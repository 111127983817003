<template>
  <span>
    <UModal
      v-model="isOpen"
      fullscreen
      :ui="{}"
    >
      <ModalCloseButton
        class="fixed top-0 right-0"
        @click="isOpen = false"
      />

      <UContainer class="flex gap-4 flex-col py-10">
        <LayoutHeader :text="$t('salesTerms.title')" />
        <div
          v-if="externalElement"
          class="external-page"
          v-html="externalElement.innerHTML"
        />
        <div v-else><p>Page content missing.</p></div>
      </UContainer>
    </UModal>
    <UButton
      variant="link"
      size="none"
      :class="btnClass"
      @click="openModal"
    ><slot>{{ btnLabel }}</slot></UButton>
  </span>
</template>

<script setup lang="ts">
defineProps({
  btnLabel: {
    type: String,
    default: 'Salgsbetingelser',
  },
  btnClass: {
    type: String,
    default: '',
  },
})

const { salesTermsSelector } = getData().branding
const { data: html } = useFetch<string>('/api/sales-terms')
let externalElement: HTMLElement | null

if (html.value) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html.value, 'text/html')
  externalElement = doc.querySelector(salesTermsSelector)
  if (externalElement) {
    const header = externalElement.querySelector('h1')
    if (header) externalElement.removeChild(header)
  }
}

const { track } = useTracker()
const isOpen = ref(false)

function openModal() {
  isOpen.value = true
  track('viewed_sales_terms')
}
</script>

<style>
.external-page p,
.external-page ul {
  margin-bottom: 1rem;
}
.external-page h2 {
  font-size: 1.2rem;
  font-weight: 600;
}
.external-page ul {
  list-style-type: disc;
  padding-left: 2rem;
}
</style>
