<template>
  <div class="flex justify-center my-10">
    <NotationAll
      type="circle"
      :iterations="2"
      :stroke-width="2"
      :padding="20"
      color="text-green-600"
    >
      <UIcon
        name="i-heroicons-hand-thumb-up"
        class="text-green-600 w-[75px] h-[75px]"
      />
    </NotationAll>
  </div>
</template>
