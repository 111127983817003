import { default as claimswW53pGTIwzMeta } from "/workspace/pages/claims.vue?macro=true";
import { default as donateXeeu4mvJrMMeta } from "/workspace/pages/donate.vue?macro=true";
import { default as feedbackkNUpblTWL6Meta } from "/workspace/pages/feedback.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as opinionTiBrYZ1MkAMeta } from "/workspace/pages/opinion.vue?macro=true";
import { default as questionsdRbzEcBDLqMeta } from "/workspace/pages/questions.vue?macro=true";
import { default as returnILnZkVcx3YMeta } from "/workspace/pages/return.vue?macro=true";
import { default as sharei3x4gNGaCmMeta } from "/workspace/pages/share.vue?macro=true";
import { default as should_45confirm_45emailXtqG4KdiaVMeta } from "/workspace/pages/should-confirm-email.vue?macro=true";
import { default as signTA6rpM6F2nMeta } from "/workspace/pages/sign.vue?macro=true";
import { default as ssnHxhfwlEHyFMeta } from "/workspace/pages/ssn.vue?macro=true";
import { default as thanksTBsJwTqaoMMeta } from "/workspace/pages/thanks.vue?macro=true";
import { default as unsubscribedepA0H7MQnWMeta } from "/workspace/pages/unsubscribed.vue?macro=true";
import { default as writedIJ7XkDoi4Meta } from "/workspace/pages/write.vue?macro=true";
export default [
  {
    name: "claims",
    path: "/claims",
    component: () => import("/workspace/pages/claims.vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/workspace/pages/donate.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/workspace/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "opinion",
    path: "/opinion",
    component: () => import("/workspace/pages/opinion.vue")
  },
  {
    name: "questions",
    path: "/questions",
    component: () => import("/workspace/pages/questions.vue")
  },
  {
    name: "return",
    path: "/return",
    component: () => import("/workspace/pages/return.vue")
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/workspace/pages/share.vue")
  },
  {
    name: "should-confirm-email",
    path: "/should-confirm-email",
    component: () => import("/workspace/pages/should-confirm-email.vue")
  },
  {
    name: "sign",
    path: "/sign",
    component: () => import("/workspace/pages/sign.vue")
  },
  {
    name: "ssn",
    path: "/ssn",
    component: () => import("/workspace/pages/ssn.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    component: () => import("/workspace/pages/thanks.vue")
  },
  {
    name: "unsubscribed",
    path: "/unsubscribed",
    component: () => import("/workspace/pages/unsubscribed.vue")
  },
  {
    name: "write",
    path: "/write",
    component: () => import("/workspace/pages/write.vue")
  }
]