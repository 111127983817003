import type { DataType } from '../data/dataType'
import no from '../data/no'

export default function (): DataType {
  // const { locale } = useRuntimeConfig().public
  const locale = process.env.LOCALE || 'NO'

  if (locale === 'NO') return no
  else throw new Error(`FATAL ERROR: missing data for locale: ${locale}`)
}
