<template>
  <UButton
    class="border-none transition hover:opacity-90 shaddow-none float-right w-[64px] cursor-pointer z-50"
    :padded="false"
    variant="link"
    size="none"
    :ui="{
      base: 'relative focus:outline-none focus-visible:outline-0 flex-shrink-0',
      rounded: '',
      variant: {
        link: 'text-{color}-500 hover:text-{color}-600 underline-offset-4 hover:underline',
      },
    }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <path
        d="M100.9,2.19c.01-2.93-.65-3.54-3.86-3.52-31.41.14-62.82.08-94.23.08.66,6.95-3.49,13.58-1.58,20.66.66,2.47.09,5.19,2.72,7.11,1.12.82.33,2.04-.09,3.15-1.56,4.06-1.77,7.76.94,11.89,2.17,3.31,4.33,6.84,2.03,11.84-1.73,3.75,2.37,8.51,5.49,11.29,5.52,4.92,8.77,10.82,12.41,16.75,2.43,3.96,8.04,4.99,12.04,7.58,1.55,1,3.04,2.81,4.45,3.08,8.04,1.58,15.47,6.95,24.43,3.49,1.76-.68,3.63.56,4.8,1.62,3.77,3.42,7.35,3.33,11.19.23,1.81-1.47,3.96-2.19,6.28-1.16,4.23,1.88,8.56,1.21,12.91.71,0-31.6-.06-63.21.09-94.81Z"
        style="fill: #ebb75f"
      />
    </svg>
    <UIcon
      name="i-heroicons-x-mark"
      class="w-[80%] h-[80%] text-red-600 absolute top-0 right-0 translate-x-0 translate-y-0"
    />
  </UButton>
</template>
