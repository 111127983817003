<template>
  <h1
    :class="decorationColor"
    class="text-3xl font-primary tracking-tight lg:text-5xl uppercase"
  >
    <span
      ref="header"
      :class="color"
    >{{ text }}</span>
  </h1>
</template>

<script lang="ts" setup>
import { annotate } from 'rough-notation'

const props = defineProps({
  text: {
    type: String,
    default: undefined,
  },
  enableAnnotation: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: 'text-black',
  },
  decorationColor: {
    type: String,
    default: 'text-primary-500',
  },
})

const header = ref()

onMounted(() => {
  if (props.enableAnnotation) {
    if (header.value) {
      const annotation = annotate(header.value, { type: 'underline', padding: [-4, 0], iterations: 2, strokeWidth: 2 })
      annotation.show()
    }
    else {
      console.error('Header ref not found.')
    }
  }
})
</script>
