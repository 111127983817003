<template>
  <UCard
    :ui="{
      base: 'p-4',
      header: {
        base: 'flex justify-between items-center pb-2 border-b-2',
      },
      body: {
        base: 'flex flex-col gap-6',
        padding: 'py-4',
      },
      footer: {
        base: 'mt-4 flex flex-row justify-between',
        padding: '',
      },
    }"
  >
    <template #header>
      <h2
        class="text-xl font-semibold"
        v-text="$t('modalCookieConsent.preferencesTitle')"
      />
      <UButton
        icon="i-heroicons-x-mark"
        color="white"
        :ui="{ rounded: 'rounded' }"
        @click="$emit('close')"
      />
    </template>

    <div
      class="text-sm flex flex-col gap-4"
      v-html="$t('modalCookieConsent.preferencesText')"
    />

    <UAccordion
      :items="items"
      :ui="{
        wrapper: 'flex flex-col w-full',
        item: {
          base: 'w-full',
          padding: 'pt-1.5 px-2 pb-3',
        },
      }
      "
    >
      <template #default="{ item, open }">
        <UButton
          color="gray"
          variant="ghost"
          class="border-b border-gray-200 px-4 flex w-full"
          :ui="{ rounded: 'rounded-none' }"
        >
          <div class="bg-gray-500 rounded-full flex justify-center align-middle">
            <UIcon
              name="i-heroicons-chevron-right-20-solid"
              class="w-4 h-4 text-white transform transition-transform duration-200 "
              :class="[open && 'rotate-90']"
            />
          </div>
          <span class="grow text-left">{{ item.label }}</span>
          <UBadge
            v-if="item.disabledToggle"
            class="w-auto"
            :label="$t('modalCookieConsent.alwaysEnabled')"
            color="gray"
          />
          <UToggle
            v-model="cookies.preferences[item.slot]"
            :disabled="item.disabledToggle"
          />
        </UButton>
      </template>
    </UAccordion>
    <template #footer>
      <div class="flex flex-row gap-2">
        <UButton
          color="white"
          size="none"
          class="px-4 py-2 rounded"
          :label="$t('modalCookieConsent.btnDeclineAll')"
          @click="cookies.disallow()"
        />
        <UButton
          color="white"
          size="none"
          class="px-4 py-2 rounded"
          :label="$t('modalCookieConsent.btnAcceptAll')"
          @click="cookies.allow(true)"
        />
      </div>
      <UButton
        size="none"
        class="px-4 py-2 rounded"
        :label="$t('modalCookieConsent.btnAcceptChoosen')"
        @click="cookies.allow(false)"
      />
    </template>
  </UCard>
</template>

<script lang="ts" setup>
const cookies = useCookieStore()

const { t } = useI18n()

defineEmits(['close'])

const items = [{
  label: t('modalCookieConsent.essential'),
  content: t('modalCookieConsent.essentialText'),
  slot: 'essential',
  disabledToggle: true,
}, {
  label: t('modalCookieConsent.functionality'),
  content: t('modalCookieConsent.functionalityText'),
  slot: 'functionality',
}, {
  label: t('modalCookieConsent.analytics'),
  content: t('modalCookieConsent.analyticsText'),
  slot: 'analytics',
}, {
  label: t('modalCookieConsent.advertising'),
  content: t('modalCookieConsent.advertisingText'),
  slot: 'advertising',
}]
</script>
