export default {
  content: [],
  theme: {
    extend: {
      aspectRatio: {
        auto: 'auto',
        square: '1 / 1',
        video: '16 / 9',
      },
      screens: {
        xs: '500px',
      },
      // https://uicolors.app/create
      colors: {
        'black-rgba': 'rgba(0, 0, 0, 0.54)',
        'vipps': '#ff5b24',
        'vipps-hover': '#ff985f',
        // 'black': '#000000',
        'black': '#202020',
        'white': '#FFFFFF',
        'facebook': '#1877F2',
        'messenger': '#0078ff',
        'instagram': '#E4405F',
        'linkedin': '#0A66C2',
        'snapchat': '#FFFC00',
        'tiktok': '#000000',
        'youtube': '#FF0000',
        /* gray: {
          50: "#f8f8f8",
          100: "#EDEDED",
          100: "#EDEDED",
          200: "#E4E4E4",
          250: "#D0D0D0",
          300: "#B2B2B2",
          400: "#B3B3B3",
          700: "#818181",
          800: "#464646",
          900: "#3d3d3d",
          950: "#292929"
        }, */
        'brown': { // 950
          50: '#f8f5f4',
          100: '#efe9e5',
          200: '#dfd0c9',
          300: '#cab1a7',
          400: '#b48e83',
          500: '#a5756a',
          600: '#98655e',
          700: '#7e5350',
          800: '#684544',
          900: '#553a39',
          950: '#372424',
        },
        // 'red': { // 700
        //   50: '#fdf3f3',
        //   100: '#fbe8e8',
        //   200: '#f7d4d5',
        //   300: '#f0b1b4',
        //   400: '#e7858c',
        //   500: '#d95a66',
        //   600: '#c43a4e',
        //   700: '#b73047',
        //   800: '#8a273b',
        //   900: '#772438',
        //   950: '#420f1a',
        // },
        'red': {
          50: '#fff7ed',
          100: '#ffeed4',
          200: '#ffd9a8',
          300: '#ffbd70',
          400: '#ff9537',
          500: '#ff7208',
          600: '#f05b06',
          700: '#c74307',
          800: '#9e350e',
          900: '#7f2e0f',
          950: '#451405',
        },
        'harvest-gold': {
          50: '#fefcf2',
          100: '#fcf6da',
          200: '#f8ecb3',
          300: '#f5de8d',
          400: '#f2d070',
          500: '#edbb60',
          600: '#eb9a37',
          700: '#dc711a',
          800: '#b2581e',
          900: '#934a1c',
          950: '#54260a',
        },
        'scarlet': {
          50: '#fff2ed',
          100: '#ffe1d5',
          200: '#fdc0ab',
          300: '#fc9575',
          400: '#f95e3e',
          500: '#f73214',
          600: '#e81c0e',
          700: '#c1100d',
          800: '#991316',
          900: '#7b1316',
          950: '#43070b',
        },
        'my-sin': {
          50: '#fffdea',
          100: '#fff7c5',
          200: '#fff085',
          300: '#ffe246',
          400: '#ffd01b',
          500: '#ffb106',
          600: '#e28500',
          700: '#bb5d02',
          800: '#984708',
          900: '#7c3b0b',
          950: '#481d00',
        },
        'green': {
          50: '#f1fcf3',
          100: '#defae5',
          200: '#bff3cb',
          300: '#8ce9a3',
          400: '#53d573',
          500: '#2cbb51',
          600: '#20a141',
          700: '#1c7934',
          800: '#1b602d',
          900: '#184f28',
          950: '#082b12',
        },

      },
    },
    fontFamily: {
      primary: ['chaney', 'sans-serif'],
      secondary: ['archia', 'sans-serif'],
      mono: ['ui-monospace', 'SFMono-Regular', 'monospace'],
      // primary: ["proxima-nova", "sans-serif"], //chaney
      // secondary: ["proxima-nova", "sans-serif"], // archia
    },
  },
  plugins: [],
}
