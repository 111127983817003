<template>
  <div
    class="fixed z-50 p-2 left-4 right-4 top-1/2 -translate-y-1/2 bg-white border rounded shadow-md max-h-[95vh] overflow-y-auto"
  >
    <div class="text-black flex flex-row flex-wrap gap-2">
      <UButton
        to="/"
        size="sm"
        label="Frontpage"
      />
      <UButton
        to="/claims"
        size="sm"
        label="Claims"
      />
      <UButton
        to="/sign"
        size="sm"
        label="Sign"
      />
      <UButtonGroup size="sm">
        <UButton
          to="/should-confirm-email"
          label="Confirm email"
        />
        <UButton
          to="/opinion?email_confirmed"
          label="Confirmed"
        />
      </UButtonGroup>
      <UButton
        to="/opinion"
        size="sm"
        label="Opinion"
      />
      <UButton
        to="/questions"
        size="sm"
        label="Questions"
      />
      <UButton
        to="/write"
        size="sm"
        label="Write"
      />
      <UButton
        to="/share"
        size="sm"
        label="Share"
      />
      <UButtonGroup size="sm">
        <UButton
          to="/donate"
          label="Donate"
        />
        <UButton
          to="/donate?thanks-for-donating"
          :external="true"
          label="Success"
        />
        <UButton
          to="/donate?error-donating"
          :external="true"
          label="Error"
        />
      </UButtonGroup>
      <UButton
        to="/ssn"
        size="sm"
        label="SSN"
      />
      <UButton
        to="/unsubscribed"
        size="sm"
        label="Unsubscribed (no change)"
      />

      <UButton
        to="/thanks"
        size="sm"
        label="Thanks"
      />

      <UButton
        to="/feedback"
        size="sm"
        label="Feedback"
      />
    </div>
    <div class="my-4">
      <UInput
        v-model="user.a_b_test"
        variant="none"
        :padded="false"
      />
    </div>
    <div class="flex flex-row flex-wrap gap-2">
      <!-- <a
        :href="`${useRoute().path}?utm_campaign=turbokylling&utm_source=mautic&utm_medium=email&utm_content=email_1`"
        class="block cursor-pointer hover:underline"
      >Add UTM to current path</a> -->
      <UButton
        size="sm"
        label="Reset page visits"
        @click="resetPageVisits()"
      />
      <UButton
        size="sm"
        label="Toggle is_recurring_donor"
        @click="user.is_recurring_donor = !user.is_recurring_donor"
      />
      <UButton
        size="sm"
        label="Toggle finished_sharing"
        @click="user.finished_sharing = !user.finished_sharing"
      />
      <UButton
        size="sm"
        label="Toggle finished_writing"
        @click="user.finished_writing = !user.finished_writing"
      />
      <UButton
        size="sm"
        label="Toggle finished_feedback"
        @click="user.finished_feedback = !user.finished_feedback"
      />
      <UButton
        size="sm"
        label="Toggle email_confirmed"
        @click="user.email_confirmed = !user.email_confirmed"
      />
      <UButton
        size="sm"
        label="Clear user cookies"
        @click="clearWebCookies"
      />
      <UButton
        size="sm"
        label="Show cookie banner"
        @click="clearConsentCookie"
      />
      <UButton
        size="sm"
        label="Clear UTMs"
        @click="clearUTM"
      />
    </div>

    <pre class="text-12 leading-tight">user: {{ storeToRefs(user) }}</pre>
    <pre class="text-12 leading-tight">cookies: {{ storeToRefs(cookies) }}</pre>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useUserStore } from '~/stores/user'

const user = useUserStore()
const cookies = useCookieStore()

function resetPageVisits() {
  user.page_visits = {}
}

const clearWebCookies = () => {
  const names = ['user']

  names.forEach((name) => {
    const cookie = useCookie(name)
    cookie.value = null
  })
}

const clearConsentCookie = () => {
  cookies.showCookieBanner = true
}

function clearUTM() {
  user.utm_source = undefined
  user.utm_medium = undefined
  user.utm_campaign = undefined
  user.utm_content = undefined
  user.utm_term = undefined
}
</script>
