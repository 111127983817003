// https://prazdevs.github.io/pinia-plugin-persistedstate/frameworks/nuxt-3.html
import * as Sentry from '@sentry/nuxt'
import { defineStore, acceptHMRUpdate } from 'pinia'
import type {
  MauticContactExtract,
  MauticContactSchema,
  MauticOpinionOption,
  MauticCoopMemberOptions,
} from '~/types'

export const a_b_test_options = ['lower_amounts', 'higher_amounts', 'vipps_button', 'small_amounts_range'] as const

export type AbTestOptions = typeof a_b_test_options[number]

export const useUserStore = defineStore(
  'user',
  () => {
    // State from API.
    const user_id = ref<number>()
    const firstname = ref<string>()
    const lastname = ref<string>()
    const phone = ref<string>()
    const email = ref<string>()
    const opinion = ref<MauticOpinionOption>()
    const email_confirmed = ref<boolean>(false)
    const finished_writing = ref<boolean>(false)
    const finished_sharing = ref<boolean>(false)
    const finished_feedback = ref<boolean>(false)
    const finished_material = ref<boolean>(false)
    const active_subscriber = ref<boolean>(false)
    const coop_member = ref<MauticCoopMemberOptions>()
    const last_active = ref<Date>()
    const a_b_test = ref<AbTestOptions>()

    // Local only
    const utm_entry = ref<string>()
    const amount_once = ref<number>()
    const amount_recurring = ref<number>()
    const is_recurring_donor = ref<boolean>(false)
    const recent_donation = ref<boolean>(false)
    const has_ssn = ref<boolean>(false)
    // current utm
    const utm_source = ref<string>()
    const utm_medium = ref<string>()
    const utm_campaign = ref<string>()
    const utm_content = ref<string>()
    const utm_term = ref<string>()
    const last_donation = ref<{
      amount?: number
      recurring?: boolean
    }>()
    const page_visits = ref<{ [key: string]: number | null }>({
      page_write: null,
      page_share: null,
      page_opinion: null,
      page_donate: null,
      page_questions: null,
    })

    // Getters.
    const fullName = computed(() => {
      if (firstname.value && lastname.value) return `${firstname.value} ${lastname.value}`.trim()
      return firstname.value ?? lastname.value ?? null
    })

    const nextPage = computed(() => {
      const time = new Date().getTime() / (1000 * 60)
      const { page_write, page_share, page_opinion, page_donate, page_questions } = page_visits.value
      const path = useRoute().path

      function visitPage(pageVisit: number | null) {
        // if (!page_write) return true
        return (time - pageVisit! > 60) // minutes
      }

      const visit_opinion = user_id.value && !opinion.value && path !== '/opinion' && visitPage(page_opinion)
      const visit_questions = user_id.value && !coop_member.value && path !== '/questions' && visitPage(page_questions)
      const visit_write = !finished_writing.value && path !== '/write' && visitPage(page_write)
      const visit_share = !finished_sharing.value && path !== '/share' && visitPage(page_share)
      const visit_donate = !is_recurring_donor.value && !recent_donation.value && visitPage(page_donate)
      const visit_donate2 = path !== '/donate' && path !== '/ssn'

      if (visit_opinion) return '/opinion'
      else if (visit_questions) return '/questions'
      else if (visit_donate) return '/donate'
      else if (visit_write) return '/write'
      else if (visit_share) return '/share'
      else if (visit_donate2) return '/donate'

      // If they have not signed the petition, push them to do so
      if (user_id.value) return '/thanks'
      else return '/sign'
    })

    const refMap: { [key: string]: Ref<unknown> } = {
      user_id,
      firstname,
      lastname,
      phone,
      email,
      is_recurring_donor,
      opinion,
      email_confirmed,
      finished_writing,
      finished_sharing,
      finished_feedback,
      finished_material,
      active_subscriber,
      last_active,
      a_b_test,
      coop_member,
    }

    function updateUser(input: Partial<MauticContactExtract>) {
      last_active.value = new Date()

      for (const key in input) {
        if (Object.prototype.hasOwnProperty.call(refMap, key)) {
          refMap[key].value = input[key]
        }
      }

      if (Sentry && email.value) Sentry.setUser({ email: email.value })
    }

    async function getUser(user_id: number) {
      const res = await $fetch<MauticContactExtract>(`/api/user/${user_id}`, { method: 'GET' })
      updateUser(res)
    }

    async function updateExternalUser(
      user_id: number | null = null,
      input: Partial<MauticContactSchema>,
    ) {
      if (user_id) {
        const res = await $fetch<MauticContactExtract>(`/api/user/${user_id}`, {
          method: 'PATCH',
          body: input,
        })
        updateUser(res)
        console.log('External user updated')
        return res
      }
      else {
        updateUser(input)
        console.log('No user_id, unable to update external user.')
        return input
      }
    }

    function logPage(path: 'write' | 'share' | 'donate' | 'opinion' | 'questions') {
      page_visits.value[`page_${path}`] = new Date().getTime() / (1000 * 60)
    }

    function getTestOption() {
      if (!a_b_test.value || (a_b_test.value && !a_b_test_options.includes(a_b_test.value))) {
        a_b_test.value = a_b_test_options[Math.floor(Math.random() * a_b_test_options.length)]
      }
      else {
        console.log('A/B test option do not need to update itself')
      }
    }

    function currentUTM() {
      return utm_source.value ? `${utm_source.value},${utm_medium.value},${utm_campaign.value},${utm_content.value},${utm_term.value}` : 'utm\'s missing'
    }

    function resetUser() {
      user_id.value = undefined
      firstname.value = undefined
      lastname.value = undefined
      phone.value = undefined
      email.value = undefined
      opinion.value = undefined
      email_confirmed.value = false
      finished_writing.value = false
      finished_sharing.value = false
      finished_feedback.value = false
      finished_material.value = false
      active_subscriber.value = false
      amount_once.value = undefined
      amount_recurring.value = undefined
      last_donation.value = {
        amount: undefined,
        recurring: undefined,
      }
      page_visits.value = {}
      last_active.value = undefined
      a_b_test.value = undefined
      utm_entry.value = undefined
    }

    return {
      // State
      user_id,
      firstname,
      lastname,
      phone,
      email,
      is_recurring_donor,
      recent_donation,
      opinion,
      email_confirmed,
      finished_writing,
      finished_sharing,
      finished_feedback,
      finished_material,
      active_subscriber,
      amount_once,
      amount_recurring,
      last_donation,
      utm_source,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term,
      last_active,
      a_b_test,
      utm_entry,
      has_ssn,
      coop_member,
      page_visits,

      // Getters
      fullName,
      nextPage,

      // Actions
      updateUser,
      getUser,
      updateExternalUser,
      logPage,
      getTestOption,
      currentUTM,
      resetUser,
    }
  },
  { persist: true },
)

// TODO: Fix this
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
