<template>
  <UContainer
    :ui="{
      base: 'mx-auto',
      padding: 'py-8 px-5 lg:py-12',
      constrained: 'grow max-w-[830px]',
    }"
  >
    <slot />
  </UContainer>
</template>

<!-- pt-6 px-5 pb-20 min-h-[100vh] lg:max-w-[830px] lg:mx-auto lg:py-28 -->
