import { v4 as uuidv4 } from 'uuid'

// Get ID to deduplicate Meta Pixel and Conversion API events
export default function (): {
  event_time_meta: number
  event_id: string
} {
  return {
    event_time_meta: Math.floor(new Date().valueOf() / 1000),
    event_id: uuidv4(),
  }
}
