export default defineAppConfig({
  ui: {
    strategy: 'override',
    primary: 'my-sin',
    // primary: 'harvest-gold',
    // primary: "scarlet",
    gray: 'slate',
    green: 'apple',
    // gray: "cole",
    modal: {
      fullscreen: 'w-screen h-screen overflow-y-auto',
    },
    button: {
      base: 'tracking-tight focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed w-auto flex justify-center transition text-center',
      rounded: 'rounded-full',
      padding: {
        none: '',
        sm: 'px-2 py-1 lg:px-3 lg:py-2',
        base: 'px-2 py-1 lg:px-3 lg:py-2',
        md: 'px-2 py-1 lg:px-3 lg:py-2',
        lg: 'px-10 py-3',
        xl: 'px-6 py-4 lg:px-8 lg:py-6',
      },
      size: {
        none: '',
        sm: 'text-sm lg:text-md',
        base: 'text-base lg:text-md',
        md: 'text-md lg:text-lg',
        lg: 'text-lg lg:text-xl',
        xl: 'text-xl lg:text-2xl',
        xxl: 'text-2xl lg:text-4xl',
      },
      variant: {
        solid:
          'font-semibold shadow-sm text-black bg-{color}-500 hover:bg-{color}-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-{color}-500 disabled:bg-gray-300 disabled:color-gray-200',
        link: 'shadow-none inline-block w-auto border-none cursor-pointer underline hover:no-underline transition',
      },
      color: {
        vipps: {
          solid: 'bg-vipps hover:bg-vipps-hover transition text-white',
        },
      },
      default: {
        size: 'lg',
      },
    },
    alert: {
      title: 'text-md font-medium',
      color: {
        primary: {
          solid: 'text-black bg-primary-100 ring-1 ring-gray-200',
        },
        white: {
          solid: 'text-gray-900 bg-gray-100',
        },
      },
      icon: {
        base: 'flex-shrink-0 w-10 h-10',
      },
    },
    card: {
      base: 'py-5 px-4',
      background: 'bg-gray-50',
      shadow: '',
      divide: '',
      ring: '',
      header: {
        base: ' text-lg font-bold',
        padding: '',
      },
      body: {
        base: 'flex flex-row flex-wrap justify-center',
        padding: 'pt-2',
      },
      footer: {
        padding: 'pt-2',
      },
    },
    accordion: {
      wrapper: 'w-full flex flex-col border-b-2',
      item: {
        base: 'mx-4',
        size: 'text-sm',
        color: '',
      },
      default: {
        class: 'py-4 w-full font-bold uppercase text-18 border-t-2',
        color: 'white',
      },
    },
    input: {
      default: {
        size: 'xl',
        color: 'customColor',
        variant: 'customVariant',
      },
      wrapper: 'relative',
      base: 'selection:bg-primary-400 w-full outline-none',
      rounded: 'rounded-none',
      form: 'bg-[rgba(0,0,0,0.05)] border border-gray-400',
      placeholder: 'placeholder-gray-400',
      padding: {
        xl: 'py-4 px-5',
      },
      size: {
        xl: 'text-14 tracking-tight font-medium',
      },
      color: {
        customColor: {
          customVariant: '',
        },
      },
    },
    radioGroup: {
      wrapper: 'relative flex items-start max-w-[500px] w-full mx-auto',
      fieldset: 'w-full',
    },
    radio: {
      label: 'text-md font-semibold text-black cursor-pointer',
      wrapper: 'py-4 px-6 my-2 flex items-start rounded border-2 has-[:checked]:border-primary-500',
      container: 'flex items-center my-auto',
      base: 'h-5 w-5 focus:ring-0 focus:ring-transparent focus:ring-offset-transparent',
    },
  },
})
