<template>
  <div
    class="relative"
    :class="color"
  >
    <div ref="notation">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { annotate } from 'rough-notation'

const props = defineProps({
  type: {
    type: String,
    default: 'underline',
    validator(value: string): boolean {
      return ['underline', 'box', 'circle', 'highlight', 'strike-through', 'crossed-off', 'bracket'].includes(value)
    },
  },
  strokeWidth: {
    type: Number,
    default: 1,
  },
  iterations: {
    type: Number,
    default: 2,
  },
  padding: {
    type: Number,
    default: 5,
  },
  color: {
    type: String,
    default: 'text-primary-500',
  },
})

const notation = ref(null)
const targetIsVisible = useElementVisibility(notation)

onMounted(() => {
  // @ts-expect-error
  const toAnotate = annotate(notation.value, { type: props.type, strokeWidth: props.strokeWidth, iterations: props.iterations, padding: props.padding })
  watch(targetIsVisible, () => {
    setTimeout(function () {
      toAnotate.show()
    }, 500)
  })
})
</script>
