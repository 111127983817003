<template>
  <div
    class="z-0 relative font-secondary max-w-screen min-h-screen bg-repeat bg-auto bg-[url(/img/page-bg.svg)]"
  >
    <NuxtLoadingIndicator :color="primaryColor" />
    <NuxtPage class="z-10" />

    <ModalDebugger v-if="debugModalShown" />
    <ModalDonationSuccess />
    <UNotifications />
    <ConsentManagementBanner />
  </div>
</template>

<script setup lang="ts">
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from './tailwind.config.js'

const { theme: { colors } } = resolveConfig(tailwindConfig)
const { primary } = useAppConfig().ui
type Colors = keyof typeof colors
const primaryColor = computed(() => colors[primary as Colors]['500'] || '#123456')

const debugModalShown = ref(false)
useSetSEO()
const { init, pageView } = useTracker()
onMounted(() => {
  init()
  pageView()
})

useHead({
  link: [
    // { rel: "prefetch", href: "/img/button/popup-close-button.webp", as: "image" },
    { rel: 'prefetch', href: '/img/page-bg.svg', as: 'image' },
    {
      rel: 'prefetch',
      href: '/fonts/chaney/chaney-regular-webfont.woff2',
      as: 'font',
    },
    {
      rel: 'prefetch',
      href: '/fonts/archia/archia-regular-webfont.woff2',
      as: 'font',
    },
    {
      rel: 'prefetch',
      href: '/fonts/archia/archia-medium-webfont.woff2',
      as: 'font',
    },
    {
      rel: 'prefetch',
      href: '/fonts/archia/archia-semibold-webfont.woff2',
      as: 'font',
    },
    {
      rel: 'prefetch',
      href: '/fonts/archia/archia-bold-webfont.woff2',
      as: 'font',
    },
  ],
})

if (process.env.NODE_ENV !== 'production') {
  defineShortcuts({
    alt_d: {
      handler: () => {
        debugModalShown.value = !debugModalShown.value
      },
    },
    escape: {
      handler: () => {
        debugModalShown.value = false
      },
    },
  })
}
</script>

<style lang="css">
/* Thin */
@font-face {
  font-family: "archia";
  src: url("/fonts/archia/archia-thin-webfont.eot");
  src: url("/fonts/archia/archia-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/archia/archia-thin-webfont.woff2") format("woff2"),
    url("/fonts/archia/archia-thin-webfont.woff") format("woff"),
    url("/fonts/archia/archia-thin-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Light */
/* @font-face {
  font-family: "archialight";
  src: url("/fonts/archia/archia-light-webfont.eot");
  src: url("/fonts/archia/archia-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/archia/archia-light-webfont.woff2") format("woff2"),
    url("/fonts/archia/archia-light-webfont.woff") format("woff"),
    url("/fonts/archia/archia-light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
} */

/* Regular */
@font-face {
  font-family: "archia";
  src: url("/fonts/archia/archia-regular-webfont.eot");
  src: url("/fonts/archia/archia-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/archia/archia-regular-webfont.woff2") format("woff2"),
    url("/fonts/archia/archia-regular-webfont.woff") format("woff"),
    url("/fonts/archia/archia-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Normal */
@font-face {
  font-family: "archia";
  src: url("/fonts/archia/archia-medium-webfont.eot");
  src: url("/fonts/archia/archia-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/archia/archia-medium-webfont.woff2") format("woff2"),
    url("/fonts/archia/archia-medium-webfont.woff") format("woff"),
    url("/fonts/archia/archia-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Semi-bold */
@font-face {
  font-family: "archia";
  src: url("/fonts/archia/archia-semibold-webfont.eot");
  src: url("/fonts/archia/archia-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/archia/archia-semibold-webfont.woff2") format("woff2"),
    url("/fonts/archia/archia-semibold-webfont.woff") format("woff"),
    url("/fonts/archia/archia-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "archia";
  src: url("/fonts/archia/archia-bold-webfont.eot");
  src: url("/fonts/archia/archia-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/archia/archia-bold-webfont.woff2") format("woff2"),
    url("/fonts/archia/archia-bold-webfont.woff") format("woff"),
    url("/fonts/archia/archia-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "chaney";
  src: url("/fonts/chaney/chaney-regular-webfont.eot");
  src: url("/fonts/chaney/chaney-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/chaney/chaney-regular-webfont.woff2") format("woff2"),
    url("/fonts/chaney/chaney-regular-webfont.woff") format("woff"),
    url("/fonts/chaney/chaney-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*
@font-face {
  font-family: "chaney-extended";
  src: url("/fonts/chaney/chaney-extended-webfont.eot");
  src: url("/fonts/chaney/chaney-extended-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/chaney/chaney-extended-webfont.woff2") format("woff2"),
    url("/fonts/chaney/chaney-extended-webfont.woff") format("woff"),
    url("/fonts/chaney/chaney-extended-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "chaney-ultra";
  src: url("/fonts/chaney/chaney-ultraextended-webfont.eot");
  src: url("/fonts/chaney/chaney-ultraextended-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/chaney/chaney-ultraextended-webfont.woff2") format("woff2"),
    url("/fonts/chaney/chaney-ultraextended-webfont.woff") format("woff"),
    url("/fonts/chaney/chaney-ultraextended-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "chaney-wide";
  src: url("/fonts/chaney/chaney-wide-webfont.eot");
  src: url("/fonts/chaney/chaney-wide-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/chaney/chaney-wide-webfont.woff2") format("woff2"),
    url("/fonts/chaney/chaney-wide-webfont.woff") format("woff"),
    url("/fonts/chaney/chaney-wide-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
</style>
