<template>
  <UModal v-model="isOpen">
    <div class="p-4 lg:pt-20 lg:pb-16 flex justify-center flex-col items-center text-center">
      <IconsThumbUp />

      <LayoutContentHeader :text="$t('modalDonationSuccess.title', { name: user.firstname })" />

      <LayoutText :text="$t('modalDonationSuccess.text')" />

      <UButton
        class="mt-6 mx-auto"
        :label="$t('btnContinue')"
        @click="isOpen = false"
      />
    </div>
  </UModal>
</template>

<script setup lang="ts">
import type { DonateSuccessSchema } from '~/utils/schemas'

const isOpen = ref(false)
const user = useUserStore()
const { track } = useTracker()

onMounted(async () => {
  const { query } = useRoute()
  if (query['thanks-for-donating'] !== undefined) {
    isOpen.value = true

    const user = useUserStore()
    const q_amount = Number(query.amount)
    const q_recurring = query.recurring ? String(query.recurring).toLowerCase() === 'true' : false

    navigateTo(useRoute().path, { replace: true })

    const { event_time_meta, event_id } = getEventID()

    if ((!user.email || !user.user_id) && query.user_id) await user.getUser(Number(query.user_id))

    const reqBody: DonateSuccessSchema = {
      user_id: user.user_id!,
      firstname: user.firstname!,
      lastname: user.lastname!,
      email: user.email!,
      phone: user.phone!,
      amount: q_amount || user.last_donation!.amount!,
      is_recurring: typeof q_recurring === 'boolean' ? q_recurring : user.last_donation!.recurring!,
      utm_donate: user.currentUTM(),
      event_time_meta: event_time_meta,
      event_id: event_id,
    }

    // NOTE: We are unable to us custom event here, if we want to use it the best way.
    track('Donate') // OUR EVENT NAME: donate_success
    const res = await $fetch('/api/donate-success', {
      method: 'POST',
      body: reqBody,
    })

    if ('user_id' in res) user.updateUser(res)
  }
})
</script>
