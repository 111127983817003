export type SnapchatEvents = 'ADD_CART' | 'PAGE_VIEW' | 'PURCHASE' | 'VIEW_CONTENT' | 'CUSTOM_EVENT_1' | 'CUSTOM_EVENT_2' | 'CUSTOM_EVENT_3' | 'CUSTOM_EVENT_4' | 'CUSTOM_EVENT_5' | 'SHARE' | 'SIGN_UP' | 'START_CHECKOUT' | 'SAVE' | 'ADD_BILLING' | 'SEARCH' | 'SUBSCRIBE' | 'COMPLETE_TUTORIAL' | 'LEVEL_COMPLETE' | 'INVITE' | 'ACHIEVEMENT_UNLOCKED' | 'ADD_TO_WISHLIST'

export interface SnapchatOptions {
  uuid_c1?: string
  user_email?: string
  user_phone_number?: string
  firstname?: string
  lastname?: string
}

export type SnapchatPixelFunction = ((cmd: 'track', event_name: SnapchatEvents, options?: SnapchatOptions) => void) & ((cmd: 'init', pixel_id: string, options?: SnapchatOptions) => void)

export const anyToSnapEvent: { [key: string]: SnapchatEvents } = {
  petition_signed: 'SIGN_UP',
  // email_confirmed: 'COMPLETE_TUTORIAL',
  email_confirmed: 'ADD_CART',
  donate_initiated: 'START_CHECKOUT',
  donate_success: 'PURCHASE',
  Donate: 'PURCHASE',
  share_finished: 'SHARE',
  write_finished: 'CUSTOM_EVENT_1',
  opinion_finished: 'CUSTOM_EVENT_2',
  questions_finished: 'CUSTOM_EVENT_3',
  feedback_finished: 'CUSTOM_EVENT_4',
}

//  SAVE  ADD_CART VIEW_CONTENT ADD_BILLING SEARCH PAGE_VIEW SUBSCRIBE AD_CLICK AD_VIEW COMPLETE_TUTORIAL LEVEL_COMPLETE INVITE LOGIN, SHARE RESERVE ACHIEVEMENT_UNLOCKED ADD_TO_WISHLIST SPENT_CREDITS RATE START_TRIAL LIST_VIEW APP_INSTALL APP_OPEN  CUSTOM_EVENT_5
