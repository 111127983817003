import type { SnapchatPixelFunction, TiktokPixelFunction } from '~/types'
import { anyToSnapEvent, anyToTiktokEvent } from '~/types'
import type { EventSchema } from '~/utils/schemas'

declare global {
  interface Window {
    snaptr: SnapchatPixelFunction
    ttq: TiktokPixelFunction
  }
}

// redditPixel: useScript({
//   key: 'reddit-pixel',
//   src: '',
//   async: true,
//   defer: true,
// }, {
//      // TODO: fix
//   use() { return { reddit: window.reddit } },
//   trigger: consentAdvertising,
//   bundle: true,
// }),

export default function () {
  const cookies = useCookieStore()
  // clarity, tiktok
  const { meta, google, snapchat } = getData()
  const { enableScripts } = useRuntimeConfig().public
  const consentAdvertising = useScriptTriggerConsent({ consent: cookies.enableAdvertising })
  const consentAnalytics = useScriptTriggerConsent({ consent: cookies.enableAnalytics })

  const scripts = {
    // Analytics
    google: useScriptGoogleAnalytics({
      id: google.analytics_id!,
      scriptOptions: {
        bundle: true,
        trigger: consentAnalytics,
      },
    }),
    // googleTagManager: useScriptGoogleTagManager({
    //   id: google.tag_manager_id!,
    //   scriptOptions: {
    //     bundle: true,
    //     trigger: consentAdvertising,
    //   },
    // }),
    // Advertising
    meta: useScriptMetaPixel({
      id: meta.pixel_id,
      scriptOptions: {
        bundle: true,
        trigger: consentAdvertising,
      },
    }),
    snapchat: useScript({
      key: 'snapchat-pixel',
      src: 'https://sc-static.net/scevent.min.js',
      async: true,
      defer: true,
      referrerpolicy: false,
      crossorigin: false,
    }, {
      use() { return { snaptr: window.snaptr } },
      trigger: consentAdvertising,
      bundle: false,
    }),
  }

  const track = async (
    event_name: string,
    event_id?: string,
    customData: { value?: number, currency?: string } = {},
    server_side: boolean = false,
    event_time_meta?: number,
  ) => {
    console.log('track:', event_name, JSON.stringify(customData), event_id)
    const user = useUserStore()

    if (!enableScripts) {
      console.log('Tracking is disabled.')
    }
    else {
      // @ts-expect-error
      scripts.google.proxy.gtag('event', event_name)
      scripts.meta.proxy.fbq('trackCustom', event_name, customData, { eventID: event_id })
      if ('ttq' in window && event_name in anyToTiktokEvent && import.meta.client) {
        window.ttq.track(anyToTiktokEvent[event_name], {}, { event_id })
      }

      // Snapchat
      if (anyToSnapEvent[event_name]) {
        // @ts-expect-error
        scripts.snapchat.proxy.snaptr('track', anyToSnapEvent[event_name], {
          uuid_c1: event_id,
          user_email: user.email,
          user_phone_number: user.phone,
          firstname: user.firstname,
          lastname: user.lastname,
        })
      }
    }

    const { meta } = getData()

    if (server_side && meta.server_side) {
      const user = useUserStore()

      const reqBody: EventSchema = {
        event_name: event_name,
        data: {
          user_id: user.user_id,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          phone: user.phone,
          event_id: event_id!,
          event_time_meta: event_time_meta!,
        },
      }

      await $fetch('/api/event', {
        method: 'POST',
        body: reqBody,
      })
    }
  }

  const pageView = () => {
    useScriptEventPage(() => {
      // @ts-expect-error
      scripts.snapchat.proxy.snaptr('track', 'PAGE_VIEW')
      // if ('ttq' in window && import.meta.client) window.ttq.page()
    })
  }

  const init = () => {
    const { preferences: { advertising, analytics } } = useCookieStore()
    scripts.snapchat.onLoaded(() => scripts.snapchat.proxy.snaptr('init', snapchat.pixel_id!))
    // if ('ttq' in window && import.meta.client) window.ttq.load(tiktok.pixel_id!)
    // @ts-expect-error
    scripts.google.proxy.gtag('consent', 'update', {
      ad_storage: advertising ? 'granted' : 'denied',
      ad_user_data: advertising ? 'granted' : 'denied',
      ad_personalization: advertising ? 'granted' : 'denied',
      analytics_storage: analytics ? 'granted' : 'denied',
    })
  }

  const advancedMatching = (
    em?: string,
    fn?: string,
    ln?: string,
    ph?: string,
    external_id?: string,
  ) => {
    scripts.meta.fbq('init', meta.pixel_id, {
      em,
      fn,
      ln,
      ph,
      external_id,
    })

    console.log('AdvancedMatching set')
  }

  return {
    track,
    pageView,
    init,
    advancedMatching,
  }
}
