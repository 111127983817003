import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '~/tailwind.config.js'

export default function () {
  const route = useRoute()

  const {
    meta,
    branding: {
      siteName,
      // siteSlogan,
      siteDescription,
      ngoName,
      siteImage,
    },
  } = getData()

  const { locale, baseUrl, index } = useRuntimeConfig().public

  const url = () => baseUrl + route.path

  const title = () => `${siteName}` //  - ${siteSlogan}

  const isFrontpage = route.path === '/'

  // Theme color
  const { theme: { colors } } = resolveConfig(tailwindConfig)
  const { primary } = useAppConfig().ui
  type Colors = keyof typeof colors
  const primaryColor = computed(() => colors[primary as Colors]['500'] || '#123456')

  const addToHead = {
    htmlAttrs: {
      lang: locale,
    },
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico',
        sizes: 'any',
      },
      {
        rel: 'icon',
        type: 'svg+xml',
        href: '/logo.svg',
        sizes: 'any',
      },
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        href: '/apple-touch-icon-180x180.png',
      },
    ],
  }

  if (isFrontpage && baseUrl) {
    addToHead.link.push({
      rel: 'canonical',
      type: 'canonical',
      href: baseUrl,
    })
  }

  useHead(addToHead)

  useSeoMeta({
    robots:
      isFrontpage && index === true
        ? 'index, noarchive, nosnippet'
        : 'noindex, nofollow, nosnippet',
    title: title,
    ogTitle: title,
    themeColor: primaryColor,
    description: siteDescription,
    ogDescription: siteDescription,
    ogImage: () => baseUrl + siteImage.path,
    ogImageWidth: siteImage.width,
    ogImageHeight: siteImage.height,
    ogImageAlt: siteImage.alt,
    // ogImageType: siteImage.mime,
    twitterTitle: title,
    twitterDescription: siteDescription,
    twitterCard: 'summary_large_image',
    twitterImage: siteImage.path,
    ogType: 'website',
    ogUrl: url,
    ogSiteName: siteName,
    ogLocale: locale,
    author: ngoName,
    // Facebook
    fbAppId: meta.app_id,
  })
}
