import type { DataType } from './dataType'

const data: DataType = {
  salesforce: {
    campaignSsnId: '701P400000MHTSmIAP',
    campaignMembershipId: '701P400000MQwTlIAL',
    campaignId: '701P4000003aumkIAA',
    successfulStatusCodes: [201, 204],
    campaignCountIDs: ['701P4000003aumkIAA'],
  },
  mautic: {
    segmentId: 110, // Special segment for this website/ petition
    campaignId: 235,
    donorRecurringSegmentId: 112,
    donorOneTimeSegmentId: 49,
    emailConfirmId: 820,
    emailThanksRecurringId: 835,
    emailThanksOneTimeId: 836,
  },
  enableCookieBanner: true,
  petition: {
    target: undefined, // 40000
    countdownDate: undefined,
  },
  donate: {
    currency: 'NOK',
    currencyLabel: 'kr',
    amountMin: 50,
    countdownDate: '2024-09-30',
    donateOptions: {
      recurring: [120, 205, 340, 575, 975, 1355],
      oneTime: [205, 310, 565, 875, 1075, 1575],
    },
    donateOptionsLower: {
      recurring: undefined,
      oneTime: undefined,
    },
    donateOptionsHigher: {
      recurring: [165, 280, 520, 882, 1496, 2538],
      oneTime: [305, 510, 865, 1465, 2490, 4225],
    },
  },
  company: {
    // Only usernames, so it works with deeplinks
    name: 'Coop',
    email: undefined,
    contactLink: 'https://kundeservice.coop.no/csp?id=csp_annet', // Can be a link
    socialMedia: {
      facebook: 'coopnorway',
      instagram: 'coop_norge',
      linkedin: 'coop-norge',
      tiktok: 'coop_norge',
      youtube: 'coopnorge',
      snapchat: 'coop_norge',
      xPost: 'Coop må droppe turbokylling! Det er ikke greit at dyrene vokser med ekstrem vekst! #FriForTurbokylling2026 https://turbokylling.no',
    },
    image: {
      fileName: 'coop.svg',
      width: 2560,
      height: 728,
    },
    whyFocus: 'Coop er en av Norges største bedrifter, og står for omtrent en tredjedel av dagligvaremarkedet. Med mange milliarder i omsetning og millioner av kunder står de i en god sitasjonen til å forbedre livene til turbokyllingene. Coop er ansvarlig for produktene de selger, og det inkluderer dyrevelferden. De kan velge å etterspøre bedre dyrevelferd fra sine leverandører og offentlige kunngjøre dette, men har så langt ikke gjort det. Coop er en av de bedriftene som gjør minst for dyrene.',
  },
  google: {
    tag_manager_id: 'GTM-TCKLW7FJ',
    analytics_id: 'G-WJQ21TB7W6',
  },
  meta: {
    app_id: '1249106892156591',
    pixel_id: '734681831807203',
    server_side: true,
  },
  snapchat: {
    pixel_id: 'a30a0e34-4801-48a1-8024-d0ea40246d33',
  },
  tiktok: {
    pixel_id: 'CRS60VRC77UDN9PK4CD0',
  },
  clarity: {
    pixel_id: 'oaylo56rjh',
  },
  branding: {
    siteName: 'Turbokylling',
    siteSlogan: 'Fri for turbokylling 2026!',
    siteDescription:
        'Signer: For et Norge fritt for turbokylling innen 2026.',
    ngoName: 'Anima - Stopp dyremishandling',
    orgNumber: '918 598 510',
    address: 'Postboks 1073 Sentrum, 0104 Oslo',
    bankAccount: '1503.88.62291',
    mainWebsite: 'https://stoppdyremishandling.no',
    privacyPolicy: 'https://stoppdyremishandling.no/personvern',
    privacyPolicySelector: 'body > div.l-page > div > div > article > div > div > div > div',
    salesTerms: 'https://stoppdyremishandling.no/salgsbetingelser',
    salesTermsSelector: 'body > div.l-page > div > div > article > div > div > div > div',
    email: 'post@stoppdyremishandling.no',
    phone: '92987765',
    spokesperson: {
      name: 'Niklas Fjeldberg',
      title: 'Kampanjeleder i Anima',
      image: 'portrett-niklas-fjeldberg.webp',
    },
    siteImage: {
      path: '/og-image.jpg',
      width: 1200,
      height: 630,
      mime: 'image/jpeg',
      alt: 'turbokylling',
    },
    logo: {
      width: 300,
      height: 56,
      light: '/img/logo/anima-logo_normal-white.svg',
      dark: '/img/logo/anima-logo_normal.svg',
      white: '/img/logo/anima-logo_white.svg',
      black: '/img/logo/anima-logo_black.svg',
    },
    icon: {
      width: 100,
      height: 100,
      light: '/img/logo/anima-logo-art_normal.svg',
      dark: '/img/logo/anima-logo-art_normal.svg',
    },
    socialMedia: {
      // Only usernames, so it works with deeplinks
      facebook: 'AnimaStoppDyremishandling',
      instagram: 'animanorge',
      snapchat: 'animanorge',
      linkedin: 'animanorge',
      tiktok: undefined,
      youtube: undefined,
      xPost: 'Norge fri for turbokylling innen 2026! Jeg støtter oppropet, gjør du? #FriForTurbokylling2026 https://turbokylling.no',
    },
  },
}

export default data
